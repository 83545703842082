import React, { FC, Suspense, useEffect, useRef } from 'react';
import {
  useEnvironment,
  useErrorBoundary,
  useExperiments,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { withSlotsPlaceholders } from '@wix/widget-plugins-ooi';
import { useSettings } from '@wix/tpa-settings/react';
import { classes, st } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { UserSelection } from './UserSelection/UserSelection';
import {
  FormState,
  SlotService,
  ServiceData,
} from '../../../utils/state/types';
import BookingDetails from './BookingDetails/BookingDetails';
import EmptyStatePage from './EmptyStatePage/EmptyStatePage';
import { EditorContextProvider } from '../Hooks/useEditorContext';
import {
  CartConflictErrorType,
  EmptyStateErrorType,
  FormError,
  GenericErrorType,
} from '../../../types/errors';
import { PaymentSelectionContainer } from './PaymentSelectionContainer/PaymentSelectionContainer';
import BackButton from './BackButton/BackButton';
import {
  isNotOffered,
  isOfferedAsPricingPlanOnlyWithoutPlansConnected,
} from '../../../utils/payment';
import { WidgetDataHooks } from './dataHooks';
import ButtonsContainer from './ButtonsContainer';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import { getErrorByType, hasErrorOfType } from '../../../utils/errors/errors';
import { FormStatus } from '../../../types/form-state';
import { FormComponentContextProvider } from '../Hooks/useFormComponentContext';
import { ExperimentsConsts } from '../../../consts/experiments';
import CartModal from './CartModal/CartModal';
import CollapseForm from './CollapseForm/CollapseForm';
import settingsParams from '../settingsParams';
import useSubmitForm from '../Hooks/useSubmitForm';
import { CartConflictMessage } from './CartConflictMessage/CartConflictMessage';
import { CouponCheckbox } from './CouponCheckbox';
import NumberOfParticipantsDropdown from './NumberOfParticipantsDropdown/NumberOfParticipantsDropdown';
import { mapToArray } from '../../../utils';
import SmsNotification from './SmsNotification/SmsNotification';

const Dialog = React.lazy(() => import('./Dialog/Dialog'));
const Header = React.lazy(() => import('./Header/Header'));
const Toast = React.lazy(() => import('./Toast/Toast'));

const PolicyDescription = React.lazy(
  () => import('./PolicyDescription/PolicyDescription'),
);

const showGlobalLoaderList = [
  FormStatus.PROCESSING_SUBMIT_ACTION,
  FormStatus.REINITIALIZING,
];

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  serviceData,
  actions,
  benefitsWithPlanInfo,
  isPricingPlanInstalled,
  formSelectedSlot,
  businessInfo,
  isMemberAreaInstalled,
  isCart,
  couponInfo,
  memberDetails,
  errors,
  editorContext,
  status,
  overrideDefaultFieldsValues,
  dialog,
  formInputs,
  cartModal,
  isServiceInCart,
  collapseFormValues,
  isUpsellPluginInstalled,
  editCollapseData,
}) => {
  const { experiments } = useExperiments();
  const formRef = useRef<FormRef>();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { error } = useErrorBoundary();
  const settings = useSettings();
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const { submitForm } = useSubmitForm();

  const isDetachNumberOfParticipantsFieldFromFormEnabled = experiments.enabled(
    ExperimentsConsts.DetachNumberOfParticipantsFieldFromForm,
  );

  const isFormUseAutomationsForSMSEnabled = experiments.enabled(
    ExperimentsConsts.FormUseAutomationsForSMS,
  );

  const { numberOfParticipants } = { ...formInputs };

  useEffect(() => {
    if (
      status === FormStatus.INITIALIZING ||
      status === FormStatus.REINITIALIZING
    ) {
      actions.initializeWidget();
    }
  }, [status, actions]);

  const isBookOnBehalfEnabled = experiments.enabled(
    ExperimentsConsts.BookOnBehalf,
  );

  const renderedEmptyState = renderEmptyStatePage({
    error,
    status,
    errors,
    serviceData,
  });

  if (renderedEmptyState) {
    return renderedEmptyState;
  }

  const toastError = getErrorByType({
    errors,
    errorType: GenericErrorType,
  });

  const cartConflictError = getErrorByType({
    errors,
    errorType: CartConflictErrorType,
  });

  const showGlobalLoader = () => showGlobalLoaderList.includes(status);

  return (
    <EditorContextProvider value={editorContext} key="form-main-widget">
      <FormActionsProvider
        value={{
          ...actions,
          submitForm: (withFormValidation) =>
            submitForm({ actions, serviceData, formRef, withFormValidation }),
        }}
      >
        <FormComponentContextProvider
          value={{
            isCartConflictError: !!cartConflictError,
            isUpsellPluginInstalled,
          }}
        >
          <Suspense
            fallback={
              <RenderGlobalLoader
                dataHook={WidgetDataHooks.SUSPENSE_GLOBAL_SPINNER}
                showGlobalLoader
              />
            }
          >
            <div
              className={st(classes.root, {
                isMobile,
                isProcessing: showGlobalLoader(),
              })}
              data-hook={WidgetDataHooks.MAIN_CONTAINER}
            >
              <div className={classes.wrapper}>
                <RenderGlobalLoader showGlobalLoader={showGlobalLoader()} />
                {toastError ? <Toast toastError={toastError} /> : null}
                <BackButton />
                {cartConflictError && <CartConflictMessage />}
                <div className={classes.body}>
                  <div className={classes.formWrapper}>
                    <Header
                      {...serviceData.form?.header}
                      {...(isBookOnBehalfEnabled && collapseFormValues
                        ? { description: undefined }
                        : {})}
                      title={
                        isServiceInCart && !isBookOnBehalfEnabled
                          ? t(
                              'app.booking-details.collapse-fields-your-details',
                            )
                          : settings.get(settingsParams.formTitle) ||
                            serviceData.form?.header?.title
                      }
                    />
                    {isMemberAreaInstalled && !isServiceInCart && (
                      <UserSelection memberDetails={memberDetails} />
                    )}
                    {collapseFormValues && (
                      <CollapseForm
                        firstName={collapseFormValues?.firstName!}
                        lastName={collapseFormValues?.lastName!}
                        email={collapseFormValues?.email!}
                        phone={collapseFormValues?.phone!}
                        serviceData={serviceData}
                        isServiceInCart={isServiceInCart}
                      />
                    )}
                    <FormInputs
                      serviceData={serviceData}
                      formSchema={serviceData.formSchema}
                      formRef={formRef}
                      memberDetails={memberDetails}
                      overrideDefaultFieldsValues={overrideDefaultFieldsValues}
                      dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                      editCollapseData={editCollapseData}
                    />
                    {isDetachNumberOfParticipantsFieldFromFormEnabled && (
                      <NumberOfParticipantsDropdown serviceData={serviceData} />
                    )}
                    <PaymentSelectionContainer
                      serviceData={serviceData}
                      memberDetails={memberDetails}
                      numberOfParticipants={numberOfParticipants}
                      benefitsWithPlanInfo={benefitsWithPlanInfo}
                      isPricingPlanInstalled={isPricingPlanInstalled}
                      dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                      status={status}
                      errors={errors}
                    />
                  </div>
                  <div className={classes.sidebar}>
                    <div className={classes.floatingContainer}>
                      <BookingDetails
                        serviceData={serviceData}
                        formSelectedSlot={formSelectedSlot}
                        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                        numberOfParticipants={numberOfParticipants}
                      />
                      <CouponCheckbox
                        serviceData={serviceData}
                        couponInfo={couponInfo}
                        isServiceInCart={isServiceInCart}
                      />
                      {serviceData.isSingleService && (
                        <PaymentSummary
                          serviceData={serviceData}
                          dateRegionalSettingsLocale={
                            dateRegionalSettingsLocale
                          }
                          numberOfParticipants={numberOfParticipants}
                          appliedCoupon={couponInfo.appliedCoupon}
                          status={status}
                        />
                      )}
                      {isFormUseAutomationsForSMSEnabled && <SmsNotification />}
                      <PolicyDescription serviceData={serviceData} />
                      <ButtonsContainer
                        serviceData={serviceData}
                        isCart={isCart}
                        isServiceInCart={isServiceInCart}
                        errors={errors}
                        status={status}
                        formInputs={formInputs}
                      />
                    </div>
                  </div>
                </div>
                {dialog ? <Dialog {...dialog.props} /> : null}
                {cartModal?.status ? (
                  <CartModal
                    cartModalStatus={cartModal?.status}
                    serviceData={serviceData}
                    formSelectedSlot={formSelectedSlot}
                    dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                    bookingIds={cartModal.bookingIds}
                    settings={settings}
                  />
                ) : null}
              </div>
            </div>
          </Suspense>
        </FormComponentContextProvider>
      </FormActionsProvider>
    </EditorContextProvider>
  );
};

const RenderGlobalLoader = ({
  showGlobalLoader,
  dataHook,
}: {
  showGlobalLoader: boolean;
  dataHook?: string;
}) => {
  if (!showGlobalLoader) {
    return null;
  }
  return (
    <>
      <div
        className={st(classes.blanket)}
        data-hook={dataHook ? dataHook : WidgetDataHooks.GLOBAL_SPINNER}
      />
      <EmptyStatePage isProcessing={showGlobalLoader} />
    </>
  );
};

const renderEmptyStatePage = ({
  status,
  errors,
  error,
  serviceData,
}: {
  status: FormStatus;
  errors: FormError[];
  error?: Error | null;
  serviceData?: ServiceData;
}) => {
  const processingStatuses = [
    FormStatus.INITIALIZING,
    FormStatus.PROCESSING_USER_DETAILS,
    FormStatus.SSR,
  ];
  const isProcessing = processingStatuses.includes(status);

  const shouldShowEmptyStatePage = () =>
    isProcessing ||
    hasErrorOfType({ errorType: EmptyStateErrorType, errors }) ||
    error;

  if (shouldShowEmptyStatePage()) {
    return <EmptyStatePage isProcessing={isProcessing} />;
  }

  const someServiceNotValid =
    !serviceData?.slotServices ||
    mapToArray<SlotService>(serviceData.slotServices).some(
      ({ service }) =>
        isOfferedAsPricingPlanOnlyWithoutPlansConnected(service?.payment) ||
        isNotOffered(service?.payment),
    );

  if (someServiceNotValid) {
    return (
      <EmptyStatePage
        isProcessing={isProcessing}
        title="app.empty-state-page.no-pp.title"
        subtitle="app.empty-state-page.no-pp.subtitle"
      />
    );
  }

  return null;
};

export default withSlotsPlaceholders(Widget);
